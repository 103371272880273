<template>
	<div class="content">
		<WithLeftMenu :blocks="withLeftMenuContent" :class="'type2'"></WithLeftMenu>
		<section class="fullPageSectionContainer setMenuColor newForm" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Feedback :type='2'></Feedback>
			</div>
		</section>
	</div>	
</template>


<script>
	import	WithLeftMenu from '@/components/app/WithLeftMenu'

	import 	projectsData from "@/assets/json/projects.json";

	export default {
		metaInfo: {
			title: 'Создание сайтов в Казани, разработка сайтов под ключ | «‎Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Разработка эффективных сайтов любой сложности. Корпоративные сайты, интернет-магазины, highload проекты. Стоимость разработки от 100000 рублей. Заполните форму на сайте, и мы вам перезвоним!' },
				{ vmid: 'og:title', property: 'og:title', content: 'Создание сайтов в Казани, разработка сайтов под ключ | «‎Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Разработка эффективных сайтов любой сложности. Корпоративные сайты, интернет-магазины, highload проекты. Стоимость разработки от 100000 рублей. Заполните форму на сайте, и мы вам перезвоним!' },
			],
		},
		data: () => ({
			withLeftMenuContent: [
				{
					component: 'projects',
					name: 'Наши проекты',
					quantity: 3,
					quantityTable: 3,
					quantityMobile: 3,
					showPagination: false,
					data: projectsData,
					//type: "Разработка сайтов",
					smallElements: true
				},{
					component: 'advantages',
					name: 'Мы делаем',
					columns: 3,
					sliderInMobile: false,
					data: [
						{
							title: 'Сайты на 1С-Битрикс',
							text: 'Проекты любой сложности на самой популярной системе управления.',
							bottomText: 'от 500 000 руб.',
							link: '/uslugi/bitrix/'
						},{
							title: 'Корпоративные сайты',
							text: 'Дизайнерские проекты с несложным функционалом.',
							bottomText: 'от 500 000 руб.',
							link: '/uslugi/korp-site/'
						},{
							title: 'Интернет-магазины',
							text: 'Проекты с каталогом товаров, корзиной, функцией оплаты онлайн и интеграцией с 1С.',
							bottomText: 'от 600 000 руб.',
							link: '/uslugi/internet-magazin/'
						},{
							title: 'Личные кабинеты',
							text: 'Автоматизация бизнес-процессов и вычислений.',
							bottomText: 'от 400 000 руб.',
						},{
							title: 'Highload проекты',
							text: 'Высоконагруженные сайты, порталы, сервисы-агрегаторы.',
							bottomText: 'от 1 000 000 руб.',
						}
					]
				},{
					component: 'advantages',
					name: 'Используемые технологии',
					columns: 2,
					sliderInMobile: false,
					data: [
						{
							title: 'CMS и фреймворк',
							text: '1С-Битрикс, Wordpress, Yii',
						},{
							title: 'Back-end (программирование)',
							text: 'PHP, Mysql, Java',
						},{
							title: 'Front-end и верстка',
							text: 'HTML5, Bootstrap, CSS3, Gulp, Jquery, Vue.js, React.js, Angular.js',
						},{
							title: 'Контроль версий',
							text: 'Bitbucket, Gitlab',
						}
					]
				},{
					component: 'advantages',
					name: 'Создаем эффективный сайт, который:',
					columns: 1,
					sliderInMobile: true,
					data: [
						{
							title: '01.',
							text: 'Отвечает запросам посетителей. Такой сайт интуитивно понятен и прост в использовании: куда ведут ссылки и как искать нужную информацию. Минимум кликов для перехода в раздел, продуманная навигация и мгновенный поиск на сайте.',
						},{
							title: '02.',
							text: 'Адаптируется под сценарии поведения людей на вашем рынке и сегменте, новые задачи вашего бизнеса и тенденции в веб-дизайне. Сайт легко меняется, если вы решите расширить географию доставки, ассортимент товаров или текст какой-нибудь кнопки.',
						},{
							title: '03.',
							text: 'Находится в верхних строчках поисковых систем и привлекает трафик. Это достигается за счёт грамотного наполнения сайта: создания семантического ядра и использования ключевых фраз. SEO-продвижение нужно постоянно, а не только в момент запуска сайта. ',
						},{
							title: '04.',
							text: 'Быстро загружается. В идеале за 0,5 сек, а через 3 секунды сайт готов к пользованию. Обидно терять часть пользователей на самом первом этапе знакомства с сайтом, поэтому не заставляйте их ждать.',
						},{
							title: '05.',
							text: 'Выдерживает нагрузки, вдвое большие, чем этого требуется. В случае обычного объёма посетителей такой сайт будет просто «летать». А в ситуации повышенного интереса он не «упадёт» и удержит потенциальных клиентов.',
						},{
							title: '06.',
							text: 'Хорошо выглядит. Грамотно продуманный дизайн останется в памяти и будет ассоциироваться с вашим брендом, не устареет через год и оставит приятные впечатления от пользования.',
						}
					]
				},{
					component: 'list',
					listType: 'dotList',
					name: 'Когда пора заказывать сайт?',
					data: [
						{
							text: 'У вас его до сих пор нет.',
						},{
							text: 'Действующий сайт не работает: не привлекает клиентов, не продаёт товар/услугу, не выставляет бренд в лучшем свете.',
						},{
							text: 'Нынешний сайт создан на конструкторе, возможности которого ограничены: нельзя добавить желаемые инструменты, вроде калькулятора и личного кабинета, и сделать свой дизайн.',
						},{
							text: 'Сайт плохо продвигается и требует доработки по этой части.',
						},{
							text: 'Сайт не соответствует вашему бизнесу: выглядит устаревшим, не содержит актуальные инструменты и контент.',
						},
					]
				}
			]
		}),
		name: 'internet-magazin',
		components: {
			Feedback: () => import('@/components/new/Forms/Feedback'),
			WithLeftMenu
		}
	}
</script>
